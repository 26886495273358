.App {
  background-color:rgb(239,239,238);
  min-height: 100vh;
  text-align: center;
  position: absolute;
}


.ContentPage{
    text-align: left;
    min-width: 100vw;
}

.MainContent{
    
}

.Footer{
    min-width: 100vw;
    bottom: 5px;
    position: absolute;
    height: fit-content;
    width: 100%;
    font-size: x-small;
}

.AppLink{
    text-decoration: none;
    color: black;
}

.DownloadItem{
    font-size: smaller;
    border-left-style: solid;
    border-width: thin;
    
    border-color: rgb(180, 180, 180);
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.DownloadButtonContainer{
    margin-top: 30px;
}

.DownloadButtonA{
    /*
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    */
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 16px;
    border-style: solid;
    border-width: thin;
    border-color: rgb(59, 59, 59);
}